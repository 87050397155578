/** @jsx jsx */
import { Icon } from "@iconify/react"
import React, { Dispatch, useEffect, useState } from "react"
import { Box, Flex, Text, ThemeUICSSObject, jsx } from "theme-ui"

import AguilaButton from "components/AguilaButton"
import LinkedLogo from "components/common/LinkedLogo"
import BulletDropdown from "components/header/BulletDropdown"
import DropdownLinks from "components/header/DropDownLinks"
import HeaderLink from "components/header/HeaderLink"

import handgunImage from "../../images/homepage/handgun-home-2x.png"
import rifleImage from "../../images/homepage/rifle-home-2x.png"
import rimfireImage from "../../images/homepage/rimfire-home-2x.png"
import shotshellImage from "../../images/homepage/shotshell-home-2x.png"

let links = [
  {
    name: "Ammunition",
    link: "/ammunition",
    bulletDropdown: [
      {
        name: "RIMFIRE",
        link: "/ammunition/rimfire",
        imgSrc: rimfireImage,
        height: "127px",
      },
      {
        name: "HANDGUN",
        link: "/ammunition/handgun",
        imgSrc: handgunImage,
        height: "114px",
      },
      {
        name: "RIFLE",
        link: "/ammunition/rifle",
        imgSrc: rifleImage,
        height: "146px",
      },
      {
        name: "SHOTSHELL",
        link: "/ammunition/shotshell",
        imgSrc: shotshellImage,
        height: "132px",
      },
    ],
  },
  {
    name: "about",
    link: "/about",
    innerLinks: [
      {
        name: "about us",
        link: "/about",
      },
      {
        name: "team aguila",
        link: "/ambassadors",
      },
      {
        name: "careers",
        link: "/contact?dept=careers",
      },
    ],
  },
  {
    name: "LE/Government",
    link: "/le-gov-services",
  },
  {
    name: "our story",
    link: "/our-story",
  },
  {
    name: "news & events",
    link: "/news",
  },
  // {
  //   name: "merchandise",
  //   link: "/merchandise",
  // },
]

const Header = ({ setOpenSearch }: { setOpenSearch: Dispatch<boolean> }) => {
  const [scrolled, setScrolled] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    document.body.style.overflow = menuOpen ? "hidden" : "auto"
  }, [menuOpen])

  useEffect(() => {
    setScrolled(window.scrollY > 0)

    const handleScroll = () => {
      setScrolled(window.scrollY > 0)
    }

    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  useEffect(() => {
    if (!window) {
      return
    }

    let timeout: NodeJS.Timer
    const handleResize = () => {
      if (window.matchMedia("(max-width: 978px)").matches) {
        setMenuOpen(false)
        setScrolled(true)
      }
    }

    const debouncedHandleResize = () => {
      clearTimeout(timeout)
      timeout = setTimeout(handleResize, 300)
    }

    window.addEventListener("resize", debouncedHandleResize)

    return () => window.removeEventListener("resize", debouncedHandleResize)
  }, [])

  const handleHamburgerClick = () => {
    setMenuOpen(!menuOpen)
  }

  const activeHamburgerStyles: ThemeUICSSObject = menuOpen
    ? {
        transform: "rotate(90deg)",
        ".ham-1, .ham-3": {
          top: "50%",
        },
        ".ham-1": {
          transform: "translateY(-50%) rotate(45deg)",
        },
        ".ham-2": {
          opacity: 0,
        },
        ".ham-3": {
          transform: "translateY(-50%) rotate(-45deg)",
        },
      }
    : {}

  const scrolledLogoStyles: ThemeUICSSObject = scrolled
    ? {
        "& > svg": {
          transition: "height 0.3s ease-in-out",
          height: ["28px", null, null, "40px"],
          width: "auto",
        },
      }
    : {}

  const scrolledNavStyles: ThemeUICSSObject = scrolled
    ? {
        py: [null, null, null, 4],
      }
    : {}

  return (
    <Box
      as="header"
      sx={{
        backgroundColor: "white",
        boxShadow: scrolled ? 0 : "none",
        left: 0,
        position: "sticky",
        top: 0,
        transition: "box-shadow 0.3s ease-in-out",
        zIndex: 99999,
      }}
    >
      <Flex
        as="nav"
        sx={{
          alignItems: "center",
          flexWrap: "wrap",
          justifyContent: "space-between",
          maxWidth: [null, null, null, "1220px"],
          mx: [null, null, null, "auto"],
          position: "relative",
          px: [null, null, null, "1rem"],
          py: [null, null, null, 6],
          transition: "padding 0.3s ease-in-out",
          ...scrolledNavStyles,
        }}
      >
        <Box
          sx={{
            pl: [4, null, null, 0],
            py: [4, null, null, 0],
          }}
        >
          <LinkedLogo
            sx={{
              "& > svg": {
                transition: "height 0.3s ease-in-out",
                height: ["28px", null, null, "60px"],
                width: "auto",
              },
              ...scrolledLogoStyles,
            }}
          />
        </Box>

        <Box
          sx={{
            py: [4, null, null, 0],
            pr: [4, null, null, 0],
            display: ["block", null, null, "none"],
          }}
        >
          <button
            type="button"
            onClick={handleHamburgerClick}
            aria-controls="main-nav"
            sx={{
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
              height: "24px",
              p: 0,
              width: "35px",
            }}
          >
            <Text variant="styles.srOnly">
              {menuOpen ? "Close Navigation" : "Open Navigation"}
            </Text>
            <span
              sx={{
                display: "block",
                height: "24px",
                position: "relative",
                transition: "all 0.3s ease-in-out",
                width: "35px",
                span: {
                  backgroundColor: "primary",
                  display: "block",
                  height: "4px",
                  left: 0,
                  position: "absolute",
                  transition: "all 0.3s ease-in-out",
                  width: "100%",
                },
                ...activeHamburgerStyles,
              }}
            >
              <span
                className="ham-1"
                sx={{
                  top: 0,
                }}
              />
              <span
                className="ham-2"
                sx={{
                  top: "50%",
                  opacity: 1,
                  transform: "translateY(-50%)",
                }}
              />
              <span
                className="ham-3"
                sx={{
                  bottom: 0,
                }}
              />
            </span>
          </button>
        </Box>

        <Box
          sx={{
            flex: ["0 0 100%", null, null, "0 0 auto"],
            order: [null, null, null, 3],
            display: "flex",
            gap: 4,
          }}
        >
          <AguilaButton
            text="find a retailer"
            variant="secondary"
            url="/find-a-retailer"
            sx={{
              textAlign: ["center", null, null, "initial"],
              width: ["100%", null, null, "50%"],
            }}
          />
          <AguilaButton
            text="shop"
            variant="secondary"
            url="https://aguilastore.com/"
            sx={{
              textAlign: [null, null, null, "initial"],
              width: [null, null, null, "[50%]"],
              display: ["none", null, null, "block"],
            }}
          />
        </Box>

        <Box
          sx={{
            backgroundColor: ["text", null, null, "transparent"],
            display: menuOpen ? "block" : ["none", null, null, "block"],
            flex: ["0 0 100%", null, null, "1"],
            height: ["calc(100vh - 101px)", null, null, "auto"],
            left: 0,
            order: [null, null, null, 2],
            pl: [9, null, null, 0],
            position: ["absolute", null, null, "relative"],
            pr: [9, null, null, 4],
            py: [6, null, null, 0],
            top: ["101px", null, null, 0],
            width: "100%",
            zIndex: 12,
          }}
        >
          <Box
            as="ul"
            id="main-nav"
            sx={{
              display: ["block", null, null, "flex"],
              justifyContent: "flex-end",
              listStyleType: "none",
              m: 0,
              pl: 0,
              width: "100%",
            }}
          >
            {links.map((item, index) => (
              <React.Fragment key={index}>
                {item.bulletDropdown && (
                  <BulletDropdown
                    menuOpen={menuOpen}
                    link={item.link}
                    name={item.name}
                    links={item.bulletDropdown}
                  />
                )}
                {item.innerLinks && (
                  <DropdownLinks
                    menuOpen={menuOpen}
                    link={item.link}
                    name={item.name}
                    links={item.innerLinks}
                  />
                )}
                {!item.innerLinks && !item.bulletDropdown && (
                  <li sx={{ mb: [6, null, null, 0] }}>
                    <HeaderLink
                      to={item.link}
                      aria-label={item.name}
                      title={item.name}
                      className="main-nav__navigation-link"
                      sx={{
                        color: ["white", null, null, "text"],
                      }}
                    >
                      {item.name}
                    </HeaderLink>
                  </li>
                )}
              </React.Fragment>
            ))}
            <button
              onClick={() => setOpenSearch(true)}
              sx={{
                variant: `links.nav`,
                border: "none",
                background: "transparent",
                cursor: "pointer",
                color: ["white", null, null, "text"],
                display: "flex",
                alignItems: "center",

                "&:hover::after": {
                  display: "none",
                },
              }}
            >
              <span
                sx={{
                  height: ["auto", null, null, 1],
                  left: ["initia", null, null, -10000],
                  overflow: "hidden",
                  position: ["relative", null, null, "absolute"],
                  top: "auto",
                  width: ["auto", null, null, 1],
                }}
              >
                Search
              </span>
              <Icon
                icon="mdi:search"
                height="20"
                fr={undefined}
                sx={{ color: ["white", null, null, "text"], ml: 3 }}
              />
            </button>
            <AguilaButton
              text="shop"
              variant="secondary"
              url="https://aguilastore.com/"
              sx={{
                display: ["block", null, null, "none"],
                width: "fit-content",
                mt: 6,
              }}
            />
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}

export default Header
