/** @jsx jsx */
import { Global, css } from "@emotion/react"
import { StaticQuery, graphql } from "gatsby"
import React, { useState } from "react"
import { jsx } from "theme-ui"

import Footer from "components/Footer"
import NewsletterSignupBanner from "components/NewsletterSignupBanner"
import Header from "components/header/Header"
import Search from "components/header/Search"
import Seo from "components/seo"

interface ILayout {
  children?: React.ReactNode | React.ReactNode[]
  seo?: any
  location: Location
  marketingEmailSignUp?: boolean
}

const Layout = ({
  location,
  children,
  seo,
  marketingEmailSignUp = false,
}: ILayout) => {
  const [openSearch, setOpenSearch] = useState(false)
  return (
    <StaticQuery
      query={graphql`
        query {
          strapiHomepage {
            seo {
              metaTitle
              metaDescription
              shareImage {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      `}
      render={data => (
        <>
          <Global
            styles={css`
              @font-face {
                font-family: "Hudson NY Regular";
                font-weight: 400;
                font-style: normal;
                src: url("/fonts/hudson-ny-regular.otf") format("opentype");
                /* src: url("Rubik.woff2") format("woff2"), url("Rubik.woff") format("woff"); */
                font-display: swap;
              }
              @font-face {
                font-family: "D-DIN";
                font-weight: 400;
                font-style: normal;
                src: url("/fonts/d-din.otf") format("opentype");
                /* src: url("Rubik.woff2") format("woff2"), url("Rubik.woff") format("woff"); */
                font-display: swap;
              }
              @font-face {
                font-family: "D-DIN Bold";
                font-weight: 800;
                font-style: normal;
                src: url("/fonts/d-din-bold.otf") format("opentype");
                /* src: url("Rubik.woff2") format("woff2"), url("Rubik.woff") format("woff"); */
                font-display: swap;
              }
            `}
          />
          <Seo seo={seo} />
          <Search openSearch={openSearch} setOpenSearch={setOpenSearch} />
          <div
            sx={{
              minHeight: "100vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Header setOpenSearch={setOpenSearch} />
            <main
              sx={{
                overflowX: "hidden",
                flex: "1 100%",
              }}
            >
              {children}
            </main>
            {marketingEmailSignUp ? (
              <NewsletterSignupBanner
                location={location}
                pageName={seo.metaTitle}
                title="Can't Find What You're Looking For?"
                content="Email our marketing team at Marketing@tx-at.com with your request. They will be able to assist you. Don't forget to subscribe below to stay up to date with everything Aguila."
              />
            ) : (
              <NewsletterSignupBanner
                location={location}
                pageName={seo.metaTitle}
              />
            )}
            <Footer />
          </div>
        </>
      )}
    />
  )
}

export default Layout
