/** @jsxRuntime classic */
/** @jsx jsx */
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { jsx, Box } from "theme-ui"

interface BGImageProps {
  alt?: string
  backgroundPosition?: string
  children?: React.ReactNode
  className?: string
  image?: IGatsbyImageData
  normalImage?: string
  overlayGradient?: Array<string | null>
}

const BgImage = ({
  alt = "",
  backgroundPosition,
  children,
  className,
  image,
  normalImage,
  overlayGradient,
}: BGImageProps) => (
  <Box
    className={className}
    sx={{
      position: "relative",
      backgroundColor: "text",
      overflow: "hidden",
      width: "100%",
      height: "100%",
      zIndex: 0,
      ":after": {
        content: '""',
        display: "block",
        position: "absolute",
        background: overlayGradient ? overlayGradient : "transparent",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        zIndex: 10,
      },
    }}
  >
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        zIndex: 50,
      }}
    >
      {children}
    </Box>
    {image && !normalImage && (
      <GatsbyImage
        alt={alt}
        image={image}
        objectPosition={backgroundPosition}
        sx={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
        }}
      />
    )}
    {!image && normalImage && (
      <img
        alt={alt}
        src={normalImage}
        sx={{
          objectFit: "cover",
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
        }}
      />
    )}
  </Box>
)

export default BgImage
