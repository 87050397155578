/** @jsxRuntime classic */
/** @jsx jsx */
import { Field, Form, Formik } from "formik"
import { motion } from "framer-motion"
import { graphql, useStaticQuery } from "gatsby"
import { useState } from "react"
import {
  Box,
  Container,
  Flex,
  Heading,
  Label,
  Paragraph,
  Text,
  jsx,
} from "theme-ui"
import * as Yup from "yup"

import AguilaButton from "components/AguilaButton"
import BgImage from "components/BgImage"

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email format").required("Required"),
})

interface NewsletterFormValues {
  email: string
}

interface NewsletterSignupBannerProps {
  //enables sx prop to be passed down from parent
  className?: string
  location: Location
  pageName: string
  title?: string
  content?: string
}

const NewsletterSignupBanner = ({
  className,
  location,
  pageName,
  title = "Subscribe to our newsletter",
  content = "Stay up to date! Enter your email to receive news and updates from Aguila.",
}: NewsletterSignupBannerProps) => {
  const [formSuccess, setFormSuccess] = useState(false)

  function formv3(emailValue: string) {
    // Create the new request
    var xhr = new XMLHttpRequest()
    var url =
      "https://api.hsforms.com/submissions/v3/integration/submit/8105048/50569b67-8a23-4b57-9fb8-bc64186f799a"

    // Example request JSON:
    var data = {
      // submittedAt: Date().toLocaleString(),
      fields: [
        {
          name: "email",
          value: emailValue,
        },
      ],
      context: {
        // hutk: ":hutk", // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
        pageUri: location.href,
        pageName: pageName,
      },
    }

    var final_data = JSON.stringify(data)

    xhr.open("POST", url)
    // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
    xhr.setRequestHeader("Content-Type", "application/json")

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200) {
        setFormSuccess(true) // alert(xhr.responseText) // Returns a 200 response if the submission is successful.
      } else if (xhr.readyState == 4 && xhr.status == 400) {
        const hubspotResponse = JSON.parse(xhr.responseText)
        if (hubspotResponse.errors[0].errorType === "INVALID_EMAIL") {
          setFormSuccess(true)
        } else {
          alert(xhr.responseText) // Returns a 400 error the submission is rejected.
        }
      } else if (xhr.readyState == 4 && xhr.status == 403) {
        console.log(403)
        alert(xhr.responseText) // Returns a 403 error if the portal isn't allowed to post submissions.
      } else if (xhr.readyState == 4 && xhr.status == 404) {
        console.log(404)
        alert(xhr.responseText) //Returns a 404 error if the formGuid isn't found
      }
    }

    // Sends the request

    xhr.send(final_data)
  }

  const data = useStaticQuery(graphql`
    {
      image: file(relativePath: { eq: "aguila-newsletter-bg2-right.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
    }
  `)

  const initialValues: NewsletterFormValues = {
    email: "",
  }

  return (
    <Box
      as="aside"
      className={className}
      sx={{ backgroundColor: "text", overflow: "hidden" }}
    >
      <BgImage
        image={data.image.childImageSharp.gatsbyImageData}
        overlayGradient={[
          "linear-gradient(90deg, rgba(255,255,255,0.7), rgba(255,255,255,0.7) 100%)",
          null,
          null,
          "transparent",
        ]}
        backgroundPosition={"75%"}
      >
        <Container sx={{ pt: 32, pb: 20, position: "relative", zIndex: 1 }}>
          <Box sx={{ maxWidth: "490px" }}>
            <Heading as="h1" variant="hero" sx={{ mb: 8 }}>
              {title}
            </Heading>
            <Paragraph
              sx={{
                mb: 12,
                fontFamily: "bodyHeavy",
                fontWeight: "700",
              }}
            >
              {content}
            </Paragraph>
            {!formSuccess && (
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                  const { email } = values
                  formv3(email)
                  actions.setSubmitting(false)
                }}
              >
                {({ errors, touched }) => (
                  <Box sx={{ position: "relative" }}>
                    <Text
                      sx={{
                        position: "absolute",
                        top: -5,
                        left: 0,
                        color: "secondary",
                        display: "block",
                        textAlign: "left",
                      }}
                    >
                      {errors.email ? errors.email : null}
                    </Text>
                    <Form
                      sx={{
                        display: "flex",
                        gap: 7,
                        flexDirection: ["column", "row"],
                        pt: 3,
                      }}
                    >
                      <Box
                        sx={{
                          position: "relative",
                          width: "100%",
                        }}
                      >
                        <Field
                          type="text"
                          name="email"
                          placeholder="Your Email"
                          sx={{
                            variant: "forms.input",
                            width: "100%",
                          }}
                        />
                        <Label htmlFor="email">Your Email</Label>
                      </Box>
                      <AguilaButton
                        type="submit"
                        text="subscribe"
                        sx={{
                          ml: [0, null, null, 8],
                          flex: ["1 0 auto", "1 0 200px"],
                          textAlign: "center",
                        }}
                      />
                    </Form>
                  </Box>
                )}
              </Formik>
            )}
            {formSuccess && (
              <Flex
                sx={{
                  width: "100%",
                  p: 6,
                  backgroundColor: "lightTan",
                  justifyContent: "center",
                  boxShadow: 0,
                }}
              >
                <Text sx={{ fontFamily: "bodyHeavy", fontWeight: "700" }}>
                  Thanks for subscribing!
                </Text>
              </Flex>
            )}
          </Box>
        </Container>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
            width: "100%",
            height: "100%",
            overflow: "hidden",
            opacity: [0, null, 1, null, null],
          }}
        >
          <motion.div
            animate={{ x: "150vw" }}
            initial={{ x: "0vw", skew: -20 }}
            transition={{
              duration: 3.6,
              repeat: Infinity,
              ease: [0, 0.67, 0.68, -0.23],
              delay: 0.5,
              repeatDelay: 2.5,
            }}
            sx={{
              // width: "clamp(128px, 20vw, 331px)",
              width: ["128px", null, "331px", null, null],
              height: ["6px", null, "16px", null, null],
              backgroundColor: "secondary",
              position: "absolute",
              top: "2%",
              left: ["-128px", null, "-400px", null, null],
              zIndex: 0,
            }}
          />
          <motion.div
            animate={{ x: "150vw" }}
            initial={{ x: "0vw", skew: -20 }}
            transition={{
              duration: 3.8,
              repeat: Infinity,
              // ease: [0, 1.09, 0.96, 0.24],
              ease: [0, 0.67, 0.68, -0.23],
              delay: 1,
              repeatDelay: 2.5,
            }}
            sx={{
              width: ["180px", null, "400px", null, null],
              height: ["6px", null, "16px", null, null],
              backgroundColor: "secondary",
              opacity: "0.5",
              position: "absolute",
              top: "6%",
              left: ["-190px", null, "-410px", null, null],
              zIndex: 0,
            }}
          />
          <motion.div
            animate={{ x: ["0%", "10%", "0%"] }}
            transition={{ duration: 10.0, repeat: Infinity }}
            initial={{ x: "100%", skew: -20 }}
            sx={{
              width: ["71px", null, "181px", null, null],
              height: ["6px", null, "16px", null, null],
              backgroundColor: "secondary",
              position: "absolute",
              top: "22%",
              left: "6%",
              zIndex: 0,
            }}
          />
          <motion.div
            animate={{ x: ["0px", "-30px", "0px"] }}
            transition={{ duration: 30, repeat: Infinity }}
            initial={{ x: "100%", skew: -20 }}
            sx={{
              width: ["128px", null, "331px", null, null],
              height: ["6px", null, "16px", null, null],
              backgroundColor: "secondary",
              position: "absolute",
              bottom: ["34%", null, "23.5%"],
              right: "-5%",
              zIndex: 0,
            }}
          />
          <motion.div
            animate={{ x: ["0%", "-10%", "0%"] }}
            transition={{ duration: 30.0, repeat: Infinity }}
            initial={{ x: "100%", skew: -20 }}
            sx={{
              width: ["189px", null, "382px", null, null],
              height: ["6px", null, "16px", null, null],
              backgroundColor: "secondary",
              opacity: "0.5",
              position: "absolute",
              bottom: ["33%", null, "20%"],
              right: "-1%",
              zIndex: 0,
            }}
          />
          <motion.div
            animate={{ x: "140vw" }}
            initial={{ x: "0vw", skew: -20 }}
            transition={{
              duration: 3.6,
              repeat: Infinity,
              ease: [0, 1.1, 0.95, 0.24],
              delay: 1.5,
              repeatDelay: 2.5,
            }}
            sx={{
              width: ["131px", null, "250px", null, null],
              height: ["6px", null, "16px", null, null],
              backgroundColor: "secondary",
              opacity: "0.5",
              position: "absolute",
              bottom: "4%",
              left: ["-115px", null, "-260px", null, null],
              zIndex: 0,
            }}
          />
          <motion.div
            animate={{ x: "140vw" }}
            initial={{ x: "0vw", skew: -20 }}
            transition={{
              duration: 3.6,
              repeat: Infinity,
              ease: [0, 0.98, 1.12, 0.24],
              delay: 1.5,
              repeatDelay: 2.5,
            }}
            sx={{
              width: ["82px", null, "141px", null, null],
              height: ["6px", null, "16px", null, null],
              backgroundColor: "secondary",
              position: "absolute",
              bottom: "0%",
              left: ["-100px", null, "-150px", null, null],
              zIndex: 0,
            }}
          />
        </Box>
      </BgImage>
    </Box>
  )
}

export default NewsletterSignupBanner
