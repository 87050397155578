/** @jsx jsx */
import { Box, Flex, Text, jsx, Link } from "theme-ui"

import FacebookIcon from "../images/icons/facebook.svg"
import InstagramIcon from "../images/icons/instagram.svg"
import TwitterIcon from "../images/icons/twitter.svg"
import YouTubeIcon from "../images/icons/youtube.svg"

interface SocialLinksProps {
  //enables sx prop to be passed down from parent
  className?: string
}

const SocialLinks = ({ className }: SocialLinksProps) => {
  return (
    <Box as="nav" sx={{ color: "tan" }}>
      <Text sx={{ display: "block", variant: "links.footerHeaderNav", mb: 5 }}>
        Follow Us
      </Text>
      <Flex
        sx={{
          "& svg": {
            height: "31px",
            width: "auto",
            mr: 3,
            path: {
              transition: "fill 0.3s ease-in-out",
              fill: "tan",
            },
          },
          "& a:hover": {
            "& svg": {
              path: {
                transition: "fill 0.3s ease-in-out",
                fill: "primary",
              },
            },
          },
        }}
      >
        <Link
          target="_blank"
          rel="noreferrer noopener"
          href="https://www.facebook.com/AguilaAmmo/"
        >
          <FacebookIcon />
        </Link>
        <Link
          target="_blank"
          rel="noreferrer noopener"
          href="https://www.instagram.com/aguilaammo/"
        >
          <InstagramIcon />
        </Link>
        <Link
          target="_blank"
          rel="noreferrer noopener"
          href="https://twitter.com/AguilaAmmo/"
        >
          <TwitterIcon />
        </Link>
        <Link
          target="_blank"
          rel="noreferrer noopener"
          href="https://www.youtube.com/channel/UCqUHWfzdZ5__zqtCzoux0vw"
        >
          <YouTubeIcon />
        </Link>
      </Flex>
    </Box>
  )
}

export default SocialLinks
