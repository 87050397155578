/** @jsx jsx */
import { jsx, Container, Paragraph } from "theme-ui"
import React, {
  ChangeEventHandler,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from "react"

import { Dialog, Modal } from "react-dialog-polyfill"

import _ from "lodash"
import { Link } from "gatsby"
import axios from "axios"
import AguilaButton from "components/AguilaButton"
import { Icon } from "@iconify/react"
import Loader from "components/common/Loader"

const fetchResults = async (string: string) => {
  return `Search string: ${string}`
}

interface ProductSearchInterface {
  objectID: string
  title: string
  sku: string
  slug: string
  product_description: string
}

const Search = ({
  openSearch,
  setOpenSearch,
}: {
  openSearch: boolean
  setOpenSearch: Dispatch<boolean>
}) => {
  //   const [active, setActive] = useState(false)
  const searchRef = useRef<HTMLInputElement>(null)
  const [search, setSearch] = useState<string>("")
  const [searchResults, setSearchResults] = useState<
    Array<ProductSearchInterface>
  >([])
  const [loading, setLoading] = useState(false)

  const handleKeypress = (ev: KeyboardEvent): any => {
    if (!openSearch) setOpenSearch(true)
    if (searchRef.current) searchRef.current.focus()
  }

  useEffect(() => {
    const fetchDataAsync = async () => {
      const { data } = await axios.post("/api/search", {
        search,
      })
      setSearchResults(data)
      setLoading(false)
    }

    if (search.length >= 3) {
      setLoading(true)
      fetchDataAsync()
    } else {
      setLoading(false)
      setSearchResults([])
    }
  }, [search])

  const [activeClass, setActiveClass] = useState(false)
  useEffect(() => {
    if (openSearch) {
      setTimeout(() => {
        setActiveClass(true)
      }, 200)

      if (searchRef.current) searchRef.current.focus()

      document.body.style.overflow = "hidden"
    } else {
      setActiveClass(false)
      if (searchRef.current) searchRef.current.value = ""
      searchRef.current?.dispatchEvent(new Event("change"))
      document.body.style.overflow = "visible"
    }
  }, [openSearch])

  // useEffect(() => {
  //   window.addEventListener("keypress", handleKeypress)
  //   return () => {
  //     window.removeEventListener("keypress", handleKeypress)
  //   }
  // }, [])

  const handleSearch = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement
    setSearch(target.value)
  }

  return (
    <Modal
      open={openSearch}
      sx={{
        maxWidth: "100%",
        maxHeight: "100%",
        ...(openSearch
          ? {
              px: 10,
              zIndex: "999999",
              position: "fixed",
              width: "100%",
              height: "100vh",
              border: "none",
              backgroundColor: "transparent",
              color: "text",
              overflowY: "auto",
            }
          : {
              display: "none",
            }),
      }}
    >
      <div
        className={activeClass ? "active" : undefined}
        sx={{
          width: "100%",
          height: "0",
          position: "fixed",
          left: 0,
          top: 0,
          backgroundColor: "lightTan",
          zIndex: "-1",
          transform: "scale(1, 0.2)",
          borderRadius: "100px",
          transition:
            "transform 0.25s, height 0.1s, border-radius 0.25s, opacity 0.05s",
          opacity: 0,
          transformOrigin: "top",
          //   transitionDelay: "0.3s",
          "&.active": {
            transform: "scale(1)",
            height: "100%",
            borderRadius: 0,
            opacity: 1,
          },
        }}
      />
      <button
        type="button"
        sx={{
          position: "sticky",
          top: 0,
          mt: 5,
          ml: "auto",
          border: "none",
          backgroundColor: "transparent",
          color: "text",
          display: "flex",
          alignItems: "center",
          textTransform: "uppercase",
          fontSize: 0,
          cursor: "pointer",
          // opacity: 0.8,
          opacity: activeClass ? 1 : 0,
          "&:hover span": {
            opacity: 1,
          },
        }}
        onClick={e => {
          e.preventDefault()
          setSearchResults([])
          setOpenSearch(false)
        }}
      >
        <Icon
          icon="mdi:close"
          height="20"
          sx={{ mr: 2, color: "primary" }}
          fr={undefined}
        />
        <span sx={{ opacity: 0.5 }}>Close</span>
      </button>
      <Container
        sx={{
          transition: "opacity 0.3s",
          opacity: activeClass ? 1 : 0,
          pt: 6,
        }}
      >
        <div
          sx={{
            display: "flex",
            alignItems: "center",
            pb: 2,
            borderBottom: "2px solid",
            borderColor: "primary",
          }}
        >
          <Icon
            icon="mdi:search"
            height="30"
            sx={{ mr: 10, color: "primary" }}
            fr={undefined}
          />
          <input
            ref={searchRef}
            onChange={_.debounce(handleSearch, 500)}
            type="text"
            placeholder="Start typing to search"
            sx={{
              background: "transparent",
              border: "none",
              color: "text",
              fontSize: [1, null, 2, null, null],
              width: "100%",
              "&::placeholder": {
                color: "text",
                opacity: 0.6,
                textTransform: "uppercase",
              },
              "&:focus": {
                outline: "none",
              },
            }}
          />
          <div>
            <Loader visible={loading} />
          </div>
        </div>

        {!loading &&
          searchRef.current &&
          searchRef.current.value.length >= 3 &&
          searchResults.length === 0 && (
            <Paragraph sx={{ mt: 5, fontSize: [2, null, null, 2] }}>
              There are no results. Try searching by product name, description,
              or SKU.
            </Paragraph>
          )}

        {searchRef.current &&
          searchRef.current.value.length < 3 &&
          searchRef.current.value.length > 0 && (
            <Paragraph sx={{ mt: 5, fontSize: [2, null, null, 2] }}>
              Please try a longer search query.
            </Paragraph>
          )}

        <ul sx={{ listStyle: "none", m: 0, p: 0, mt: 5 }}>
          {searchResults.map(
            ({ objectID, title, sku, product_description, slug }) => {
              return (
                <li
                  key={objectID}
                  sx={{
                    mb: 4,
                    px: 4,
                    pt: 8,
                    pb: 8,

                    position: "relative",
                    "&:not(:last-child):after": {
                      position: "absolute",
                      left: 0,
                      bottom: 0,
                      width: "100%",
                      content: '""',
                      height: 1,
                      backgroundColor: "text",
                    },
                  }}
                >
                  <AguilaButton
                    sx={{
                      display: "block",
                      fontFamily: "heading",
                      fontSize: 3,
                      color: "primary",
                      m: 0,
                      p: 0,
                      "& .link-text": {
                        fontSize: 3,
                        p: 0,
                      },
                    }}
                    text={title}
                    variant="textOnly"
                    url={`/products/${slug}`}
                  />
                  <span sx={{ mt: 2, mb: 3, display: "block" }}>SKU {sku}</span>

                  <p sx={{ m: 0, maxWidth: 500 }}>{product_description}</p>
                </li>
              )
            }
          )}
        </ul>
      </Container>
    </Modal>
  )
}

export default Search
