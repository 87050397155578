/** @jsx jsx */
import { Box, jsx } from "theme-ui"
import { Link } from "gatsby"
import { useState, createRef } from "react"

import HeaderLink from "components/header/HeaderLink"

interface DropDownLinksProps {
  className?: string
  link: string
  links: { link: string; name: string }[]
  menuOpen: boolean
  name: string
}

const DropDownLinks = ({
  className,
  link,
  links,
  menuOpen,
  name,
}: DropDownLinksProps) => {
  const [listHeight, setListHeight] = useState("0px")

  const list = createRef<HTMLDivElement>()

  const handleDropdownClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (menuOpen) {
      e.preventDefault()
      setListHeight(
        listHeight === "0px" ? list.current?.scrollHeight + "px" : "0px"
      )
    }
  }

  const handleKeyUp = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (menuOpen) {
      if (e.code === "Space") {
        setListHeight(
          listHeight === "0px" ? list.current?.scrollHeight + "px" : "0px"
        )
      }
    }
  }

  return (
    <li
      className={className}
      sx={{
        mb: [6, null, null, 0],
        pb: "3px",
        position: "relative",
        "&:focus-within": {
          ".header-dropdown-nav": {
            maxHeight: [null, null, null, "600px"],
            opacity: [null, null, null, 1],
          },
        },
        ":hover": {
          ".header-dropdown-nav": {
            maxHeight: [null, null, null, "600px"],
            opacity: [null, null, null, 1],
          },
        },
      }}
    >
      <HeaderLink
        aria-label={name}
        onClick={handleDropdownClick}
        onKeyUp={handleKeyUp}
        title={name}
        to={link}
        variant="navDropDownLink"
        sx={{
          alignItems: "center",
          color: ["white", null, null, "text"],
          display: "inline-flex",
        }}
      >
        <span sx={{ mr: [4, null, null, 0], lineHeight: "100%" }}>{name}</span>
        <span
          sx={{
            display: ["block", null, null, "none"],
            height: "15px",
            position: "relative",
            transform:
              listHeight !== "0px" ? "rotate(0deg)" : "rotate(-180deg)",
            transition: "transform 0.3s ease-in-out",
            width: "15px",
            "&::before, &::after": {
              backgroundColor: "primary",
              content: '""',
              display: "block",
              height: "2px",
              left: 0,
              position: "absolute",
              top: "50%",
              transition: "opacity 0.3s ease-in-out",
              width: "100%",
            },
            "::before": {
              opacity: listHeight !== "0px" ? 0 : 1,
              transform: "translateY(-50%) rotate(90deg)",
            },
            "::after": {
              transform: "translateY(-50%)",
            },
          }}
        ></span>
      </HeaderLink>

      <Box
        ref={list}
        className="header-dropdown-nav"
        sx={{
          height: [listHeight, null, null, "auto"],
          left: 0,
          maxHeight: [null, null, null, 0],
          opacity: [null, null, null, 0],
          overflow: "hidden",
          position: [null, null, null, "absolute"],
          top: "calc(100%)",
          transition:
            "max-height 0.3s ease-in-out, height 0.3s ease-in-out, opacity 0.3s ease-in-out",
          width: "fit-content",
          ":hover": {
            maxHeight: [null, null, null, "600px"],
            opacity: [null, null, null, 1],
          },
          "&:focus-within": {
            maxHeight: [null, null, null, "600px"],
            opacity: [null, null, null, 1],
            height: ["auto"],
          },
        }}
      >
        <Box
          as="ul"
          sx={{
            backgroundColor: "text",
            borderTopColor: "primary",
            borderTopStyle: "solid",
            borderTopWidth: [0, null, null, "4px"],
            listStyleType: "none",
            position: "relative",
            px: [2, null, null, 6],
            py: 4,
          }}
        >
          {links.map((item, index) => (
            <li
              key={item.name}
              sx={{
                ":not(:first-of-type)": {
                  mt: 2,
                },
              }}
            >
              <Link
                to={item.link}
                sx={{
                  color: "lightTan",
                  fontFamily: "body",
                  fontSize: "13px",
                  fontWeight: "normal",
                  textDecoration: "none",
                  textTransform: "uppercase",
                  whiteSpace: "nowrap",
                }}
              >
                <span>{item.name}</span>
              </Link>
            </li>
          ))}
        </Box>
      </Box>
    </li>
  )
}

export default DropDownLinks
