/** @jsx jsx */
import { Link } from "gatsby"
import { Box, Flex, Heading, jsx } from "theme-ui"
import { motion } from "framer-motion"

import DropdownLinks from "components/header/DropDownLinks"

interface BulletDropdownProps {
  className?: string
  link: string
  links: { link: string; name: string; imgSrc: string; height: string }[]
  menuOpen: boolean
  name: string
}

const BulletDropdown = ({
  className,
  link,
  links,
  menuOpen,
  name,
}: BulletDropdownProps) => {
  if (menuOpen) {
    return (
      <DropdownLinks
        menuOpen={menuOpen}
        link={link}
        name={name}
        links={links}
      />
    )
  }

  return (
    <li
      className={className}
      sx={{
        position: "relative",
        "&:focus-within": {
          ".bullet-list": {
            maxHeight: "1200px",
            opacity: 1,
          },
        },
        ":hover": {
          ".bullet-list": {
            maxHeight: "1200px",
            opacity: 1,
          },
        },
      }}
    >
      <Link
        to={link}
        title={name}
        sx={{
          variant: "links.navDropDownLink",
        }}
      >
        {name}
      </Link>

      <Box
        className="bullet-list"
        sx={{
          backgroundColor: "text",
          left: "-40%",
          maxHeight: 0,
          opacity: 0,
          overflow: "hidden",
          position: "absolute",
          top: "calc(100%)",
          transition: "all 0.3s ease-in-out",
          width: "fit-content",
        }}
      >
        <Flex
          sx={{
            alignItems: "flex-end",
            borderTopColor: "primary",
            borderTopStyle: "solid",
            borderTopWidth: "4px",
            pb: 4,
            pt: 8,
            px: 6,
          }}
        >
          {links.map((item, index) => (
            <Link
              key={index}
              to={item.link}
              sx={{
                mx: 6,
                textAlign: "center",
                textDecoration: "none",
              }}
            >
              <motion.div
                whileHover={{
                  y: ["0%", "-10%", "0%"],
                  transition: { duration: 2, repeat: Infinity },
                }}
              >
                <img
                  src={item.imgSrc}
                  alt=""
                  sx={{
                    height: item.height,
                    maxWidth: "100%",
                    verticalAlign: "middle",
                  }}
                />

                <Heading
                  as="h3"
                  variant="title"
                  sx={{
                    color: "tan",
                    mt: 3,
                    textAlign: "center",
                    zIndex: 1,
                  }}
                >
                  {item.name}
                </Heading>
              </motion.div>
            </Link>
          ))}
        </Flex>
      </Box>
    </li>
  )
}

export default BulletDropdown
