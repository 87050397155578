/** @jsx jsx */
import { jsx, ThemeUICSSObject } from "theme-ui"
import { keyframes } from "@emotion/react"

interface LoaderInterface {
  visible: boolean
  className?: string
}

const rotateLeft = keyframes`
  100% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const innerStyles: ThemeUICSSObject = {
  position: "absolute",
  borderRadius: "50%",
  border: "8px solid transparent",
  boxSizing: "border-box",
  opacity: "0.9",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
}

const Loader = ({ visible, className }: LoaderInterface) => {
  return (
    <div
      className={className}
      sx={{
        opacity: visible ? "1" : "0",
        position: "relative",
        width: "38px",
        height: "38px",
      }}
    >
      <div
        className="inner"
        sx={{
          ...innerStyles,
          borderLeft: "6px solid",
          borderRight: "6px solid",
          borderLeftColor: "text",
          borderRightColor: "text",
          animation: `${rotateLeft} 1.75s`,
          animationIterationCount: "infinite",
          animationTimingFunction: "linear",
        }}
      />
      <div
        className="inner"
        sx={{
          ...innerStyles,
          borderLeft: "6px solid",
          borderRight: "6px solid",
          borderLeftColor: "primary",
          borderRightColor: "primary",
          animation: `${rotateLeft} 3s`,
          animationIterationCount: "infinite",
          animationTimingFunction: "linear",
        }}
      />
      <div
        className="inner"
        sx={{
          ...innerStyles,
          borderLeft: "6px solid",
          borderRight: "6px solid",
          borderLeftColor: "secondary",
          borderRightColor: "secondary",
          animation: `${rotateLeft} 4s`,
          animationIterationCount: "infinite",
          animationTimingFunction: "linear",
        }}
      />
    </div>
  )
}

export default Loader
