/** @jsx jsx */
import React from "react"
import { Link } from "gatsby"
import { jsx } from "theme-ui"

interface HeaderLinkProps {
  children: React.ReactNode
  className?: string
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  onKeyUp?: React.KeyboardEventHandler<HTMLAnchorElement>
  title: string
  to: string
  variant?: "nav" | "navDropDownLink"
}

const HeaderLink = ({
  children,
  className,
  onClick,
  onKeyUp,
  title,
  to,
  variant = "nav",
  ...props
}: HeaderLinkProps) => {
  const internal = /^\/(?!\/)/.test(to)

  if (internal) {
    return (
      <Link
        {...props}
        className={className}
        onClick={onClick}
        onKeyUp={onKeyUp}
        title={title}
        to={to}
        sx={{ variant: `links.${variant}` }}
      >
        {children}
      </Link>
    )
  }

  return (
    <a
      {...props}
      className={className}
      href={to}
      onClick={onClick}
      onKeyUp={onKeyUp}
      rel="noopener noreferrer"
      target="_blank"
      title={title}
      sx={{ variant: `links.${variant}` }}
    >
      {children}
    </a>
  )
}

export default HeaderLink
