/** @jsx jsx */
import { Link, graphql, useStaticQuery } from "gatsby"
import {
  Box,
  Container,
  Flex,
  Grid,
  Heading,
  Text,
  Link as ThemeLink,
  jsx,
} from "theme-ui"

import FooterLogo from "../images/icons/aguila-footer-logo.svg"

import AguilaButton from "components/AguilaButton"
import SocialLinks from "components/SocialLinks"

interface FooterProps {
  //enables sx prop to be passed down from parent
  className?: string
}
export interface IMyLink {
  url: string
  text: string
  rel?: string
}

type ListHeading = {
  url?: string
  text: string
}

export interface FooterNavList {
  heading: ListHeading
  links: IMyLink[]
}

const Footer = ({ className }: FooterProps) => {
  const data = useStaticQuery(graphql`
    {
      strapiGlobal {
        catalog {
          localFile {
            url
          }
        }
      }
    }
  `)

  const navLists: FooterNavList[] = [
    {
      heading: {
        url: "/ammunition",
        text: "Ammunition",
      },
      links: [
        {
          url: "/ammunition/rimfire",
          text: "Rimfire",
        },
        {
          url: "/ammunition/handgun",
          text: "Handgun",
        },
        {
          url: "/ammunition/rifle",
          text: "Rifle",
        },
        {
          url: "/ammunition/shotshell",
          text: "Shotshell",
        },
      ],
    },
    {
      heading: {
        text: "Company Information",
      },
      links: [
        {
          url: "/about",
          text: "About",
        },
        {
          url: "/ambassadors",
          text: "Team Aguila",
        },
        {
          url: "/le-gov-services",
          text: "LE/Government",
        },
        {
          url: "/our-story",
          text: "Our Story",
        },
        {
          url: "/news",
          text: "News & Events",
        },

        // {
        //   url: "#",
        //   text: "Merchandise",
        // },
      ],
    },
    {
      heading: {
        text: "Support",
      },
      links: [
        {
          url: "/faq",
          text: "FAQs",
        },
        {
          url: "/warranty-information",
          text: "Warranty Information",
        },
        {
          url: `${data.strapiGlobal.catalog.localFile.url}`,
          text: "Download Catalog",
        },
        {
          url: "/marketing",
          text: "Marketing",
        },
      ],
    },
    {
      heading: {
        text: "Contact",
      },
      links: [
        {
          url: "/contact?dept=gov_law",
          text: "LE/Government Sales",
        },
        {
          url: "/contact?dept=sponsor_partner",
          text: "Sponsorship & Partnerships",
        },
        {
          url: "/contact?dept=gen_inquiries",
          text: "Inquires & Product Support",
        },
      ],
    },
    {
      heading: {
        text: "Legal",
      },
      links: [
        {
          url: "/terms-and-conditions",
          text: "Terms & Conditions",
        },
        {
          url: "/privacy-policy",
          text: "Privacy Policy",
        },
      ],
    },
  ]
  return (
    <Box as="footer" sx={{ backgroundColor: "text", color: "tan", py: 12 }}>
      <Container>
        <Flex sx={{ flexDirection: ["column", null, "row"] }}>
          <Flex
            sx={{ flex: ["1 100%", null, "0 1 320px"], alignItems: "center" }}
          >
            <Box
              sx={{ mt: 2.5, flex: ["1 50%", null, "1 100%"], height: "100%" }}
            >
              <Link to="/" aria-label="Aguila Ammunition" className={className}>
                <FooterLogo
                  sx={{
                    width: ["104px", null, "178px"],
                    height: "auto",
                    "& path": { fill: "tan" },
                    mb: [6, null, 12],
                  }}
                />
                <Text variant="srOnly">Home</Text>
              </Link>
              <Box sx={{ display: ["none", null, "block"] }}>
                <Text
                  sx={{ display: "block", variant: "text.subtitle", mb: 5 }}
                >
                  Ready to buy?
                </Text>
                <AguilaButton
                  url="/find-a-retailer"
                  text="Find a retailer"
                  sx={{ color: "tan" }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: ["block", null, "none"],
                flex: ["1 50%", null, "1 100%"],
              }}
            >
              <SocialLinks />
            </Box>
          </Flex>
          <Box
            as="nav"
            sx={{
              flex: "1 auto",
            }}
          >
            <Grid
              sx={{
                gridTemplateColumns: ["1fr 1fr", "2fr 3fr 2fr"],
              }}
            >
              {navLists.map(({ heading, links }, index) => (
                <Box key={index} sx={{ mb: 6 }}>
                  {heading.url && (
                    <Link
                      to={heading.url}
                      sx={{
                        display: "block",
                        variant: "links.footerHeaderNav",
                        mb: 5,
                      }}
                    >
                      {heading.text}
                    </Link>
                  )}
                  {!heading.url && (
                    <Heading variant="links.footerHeaderNav" sx={{ mb: 5 }}>
                      {heading.text}
                    </Heading>
                  )}

                  <ul sx={{ p: 0, m: 0, listStyle: "none" }}>
                    {links.map(({ url, text }, index) => {
                      const internal = /^\/(?!\/)/.test(url)

                      if (internal) {
                        return (
                          <li key={index}>
                            <Link to={url} sx={{ variant: "links.footerNav" }}>
                              {text}
                            </Link>
                          </li>
                        )
                      } else {
                        return (
                          <li key={index}>
                            <ThemeLink
                              href={url}
                              sx={{ variant: "links.footerNav" }}
                            >
                              {text}
                            </ThemeLink>
                          </li>
                        )
                      }
                    })}
                  </ul>
                </Box>
              ))}
              <Box
                sx={{
                  display: ["none", null, "block"],
                }}
              >
                <SocialLinks />
              </Box>
            </Grid>
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

export default Footer
