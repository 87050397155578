/** @jsx jsx */
import { Text, jsx } from "theme-ui"
import { Link } from "gatsby"

import AguilaLogo from "images/icons/aguila-logo-v2.svg"

interface LinkedLogoProps {
  //enables sx prop to be passed down from parent
  className?: string
}

const LinkedLogo = ({ className }: LinkedLogoProps) => {
  return (
    <Link
      to="/"
      aria-label="Aguila Ammunition"
      className={className}
      sx={{ display: "inline-block" }}
    >
      <AguilaLogo
        sx={{ width: "218px", height: "auto", verticalAlign: "middle" }}
      />
      <Text variant="srOnly">Home</Text>
    </Link>
  )
}

export default LinkedLogo
